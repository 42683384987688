/*
      www.OnlineWebFonts.Com 
      You must credit the author Copy this link on your web 
      <div>Font made from <a href="http://www.onlinewebfonts.com">oNline Web Fonts</a>is licensed by CC BY 3.0</div>
      OR
      <a href="http://www.onlinewebfonts.com">oNline Web Fonts</a>
*/
@font-face {font-family: "Noteworthy";
    src: url("//db.onlinewebfonts.com/t/f4bc352b52c96ca087093d36bd2396bb.eot"); /* IE9*/
    src: url("//db.onlinewebfonts.com/t/f4bc352b52c96ca087093d36bd2396bb.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("//db.onlinewebfonts.com/t/f4bc352b52c96ca087093d36bd2396bb.woff2") format("woff2"), /* chrome firefox */
    url("//db.onlinewebfonts.com/t/f4bc352b52c96ca087093d36bd2396bb.woff") format("woff"), /* chrome firefox */
    url("//db.onlinewebfonts.com/t/f4bc352b52c96ca087093d36bd2396bb.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("//db.onlinewebfonts.com/t/f4bc352b52c96ca087093d36bd2396bb.svg#Noteworthy") format("svg"); /* iOS 4.1- */
}